import type { ComponentProps, ReactNode } from 'react'
import { useContext } from 'react'

import { CSSReset } from './cssreset'
import { CSSScope } from './cssscope'
import { CSSResetContext } from './theme/cssreset-context'
import { ThemeContext } from './theme/theme-context'
import { defaultTheme } from './theme/themes/default.theme'
import type { Theme } from './theme/types'

type Props = {
    theme?: Theme
    children: ReactNode
    wrapperProps?: ComponentProps<typeof CSSScope>
}

// eslint-disable-next-line @eslint-react/no-unstable-context-value
export const ThemeProvider = ({ theme = defaultTheme, children, wrapperProps }: Props) => {
    const isTopLevel = useContext(CSSResetContext)

    return (
        <ThemeContext.Provider value={theme}>
            <CSSReset isTopLevel={isTopLevel} />

            <CSSResetContext.Provider value={false}>
                <CSSScope {...wrapperProps}>{children}</CSSScope>
            </CSSResetContext.Provider>
        </ThemeContext.Provider>
    )
}
