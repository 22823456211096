import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'

import { createViewTransitionCss } from './components/drawer/drawer'
import { useTheme } from './utils/use-theme'

// This template was generated by the "fontpie" library.
// pnpm dlx fontpie .storybook/public/fonts/avenir/avenir_roman.woff2 --name Adverity
// language=css
const fontPieCss = `
    @font-face {
        font-family: 'Avenir Fallback';
        font-style: normal;
        font-weight: 400;
        src: local('Arial');
        ascent-override: 94.39%;
        descent-override: 24.61%;
        line-gap-override: 19.69%;
        size-adjust: 101.59%;
    }
`

const avenirFallback = {
    name: 'avenir-fallback',
    styles: fontPieCss,
}

export const GlobalStyles = ({ disableAnimations = false }: { disableAnimations?: boolean }) => {
    const theme = useTheme()

    const styles = css(
        emotionNormalize,
        avenirFallback,
        {
            '*, ::after, ::before': {
                boxSizing: 'border-box',
            },
            body: {
                fontFamily: theme.fonts.body,
                fontSize: theme.fontSizes.small,
                lineHeight: theme.lineHeights['xx-small'],
                color: theme.colors.text,
                margin: 0,
            },
            // eslint-disable-next-line id-length
            a: {
                textDecoration: 'none',
            },
            ...(disableAnimations
                ? {
                      '*, ::after, ::before, ::view-transition-group(*), ::view-transition-old(*), ::view-transition-new(*)':
                          {
                              animation: 'none !important',
                              transition: 'none !important',
                          },
                  }
                : {}),
        },
        // viewTransition styles for drawer animations
        createViewTransitionCss('right'),
        createViewTransitionCss('bottom'),
        createViewTransitionCss('left'),
        {
            // Respect prefers-reduced-motion for view transitions
            '@media (prefers-reduced-motion)': {
                '::view-transition-group(*), ::view-transition-old(*), ::view-transition-new(*)': {
                    animation: 'none !important',
                },
            },
        },
    )

    return <Global styles={styles} />
}
